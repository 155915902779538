import React, { useEffect, useState } from "react";
import HeaderHome from "../../Components/Header_Home/Header_Home";
import "./Treatments.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faTooth } from "@fortawesome/free-solid-svg-icons";

const Treatments = () => {


    const [activeIdx, setActiveIdx] = useState<number>(window.innerWidth < 900 ? -1 : 0);

    const changeActiveIdx = (newActiveIdx : number) => {
        if (window.innerWidth < 900) {
            console.log(activeIdx, newActiveIdx);
            if (activeIdx === newActiveIdx) setActiveIdx(-1);
            else setActiveIdx(newActiveIdx);
        } else setActiveIdx(newActiveIdx);
    };


    return (
        <div className="home-container">
            <HeaderHome activePage="treatments" data={null}/>
            <div className="content">
                <div className="block-1 block-container">
                    <h2 className="title">Tratamente</h2>
                    <div className="treatments">
                        <div className="names-container">

                            {window.innerWidth > 900 || activeIdx >= 0 ? <div className={`name-container ${activeIdx === 0 ? "active" : ""}`} onClick={() => changeActiveIdx(0)}>
                                <p>Servicii terapie, profilaxie, igienizare	</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 1 ? <div className={`name-container ${activeIdx === 1 ? "active" : ""}`} onClick={() => changeActiveIdx(1)}>
                                <p>Servicii odontoterapie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 2 ? <div className={`name-container ${activeIdx === 2 ? "active" : ""}`} onClick={() => changeActiveIdx(2)}>
                                <p>Ortodontie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 3 ? <div className={`name-container ${activeIdx === 3 ? "active" : ""}`} onClick={() => changeActiveIdx(3)}>
                                <p>Servicii endodontie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 4 ? <div className={`name-container ${activeIdx === 4 ? "active" : ""}`} onClick={() => changeActiveIdx(4)}>
                                <p>Servicii chirurgie mica, urgente</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 5 ? <div className={`name-container ${activeIdx === 5 ? "active" : ""}`} onClick={() => changeActiveIdx(5)}>
                                <p>Pedodontie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 6 ? <div className={`name-container ${activeIdx === 6 ? "active" : ""}`} onClick={() => changeActiveIdx(6)}>
                                <p>Servicii chirurgie omf (medic specialist)</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}
                            
                            {window.innerWidth > 900 || activeIdx >= 7 ? <div className={`name-container ${activeIdx === 7 ? "active" : ""}`} onClick={() => changeActiveIdx(7)}>
                                <p>Servicii parodontologie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 8 ? <div className={`name-container ${activeIdx === 8 ? "active" : ""}`} onClick={() => changeActiveIdx(8)}>
                                <p>Servicii implantologie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 9 ? <div className={`name-container ${activeIdx === 9 ? "active" : ""}`} onClick={() => changeActiveIdx(9)}>
                                <p>Servicii protetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 10 ? <div className={`name-container ${activeIdx === 10 ? "active" : ""}`} onClick={() => changeActiveIdx(10)}>
                                <p>Servicii estetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 11 ? <div className={`name-container ${activeIdx === 11 ? "active" : ""}`} onClick={() => changeActiveIdx(11)}>
                                <p>Servicii pedodontie (Tratamente copii)</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}
                        </div>

                        {activeIdx === 0 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Consultatie</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Consultatie urgenta</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Consultatie de specialitate Parodontologica</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Anestezie locala prin infiltratie</p>
                                </div>
                                <div className="column">
                                    <p>20 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Detartraj</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Periaj profesional</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Air flow</p>
                                </div>
                                <div className="column">
                                    <p>50 lei / arcada</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Pachet igienizare complet</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Modele de studiu</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 1 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Coafaj</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit laterali (1 suprafata)</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit laterali (2 suprafata)</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit laterali (3 suprafata)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit frontali</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                           
                        </div> : null}

                        {activeIdx === 2 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Aparat dentar mobil / arcada	</p>
                                </div>
                                <div className="column">
                                    <p>1000 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Gutiera albire/bruxism</p>
                                </div>
                                <div className="column">
                                    <p>300 lei / arcada</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Reparatie aparat mobil</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Control aparat mobil</p>
                                </div>
                                <div className="column">
                                    <p>100 lei per arcada</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Aparate fixe / arcada Metal</p>
                                </div>
                                <div className="column">
                                    <p>2400 lei /arcada</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparate fixe / arcada Ceramic</p>
                                </div>
                                <div className="column">
                                    <p>4000 lei /arcada</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparate fixe / arcada Safir</p>
                                </div>
                                <div className="column">
                                    <p>5000 lei / arcada</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Control aparat/e fix/e / luna</p>
                                </div>
                                <div className="column">
                                    <p>150 lei / ambele arcade</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 3 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extirpare vitala - dinte monoradiculari</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extirpare vitala - dinte pluriradiculari - premolar (2 canale)</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extirpare vitala - dinte pluriradiculari - molar (3 canale)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie canal - dinte monoradiculari</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie de canal - dinte pluriradiculari - premolar (2 canale)</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Obturatie de canal - dinte pluriradiculari - molar (3 canale)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Dezobturare canal radicular</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Drenaj endodontic</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Obturatie canal provizorie - dinte monoradiculari</p>
                                </div>

                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Obturatie canal provizorie - dinte pluriradiculari</p>
                                </div>

                                <div className="column">
                                    <p>130 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 4 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinti frontali monoradiculari</p>
                                </div>
                                <div className="column">
                                    <p>150 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinti laterali pluriradiculari</p>
                                </div>
                                <div className="column">
                                    <p>250 lei - 300 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinte parodontotic</p>
                                </div>
                                <div className="column">
                                    <p>80 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie alveoloplastica</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie molar minte (erupt)</p>
                                </div>
                                <div className="column">
                                    <p>350 - 400 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Extractie molar minte (inclus)</p>
                                </div>
                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Gingivectomie cu gingivoplastie</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Incizie abces vestibular</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Imobilizare</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 5 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Sigilare</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Extractie</p>
                                </div>
                                <div className="column">
                                    <p>100 lei - 200 lei</p>
                                </div>
                            </div>
                            
                        </div> : null}

                        {activeIdx === 6 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Rezectie apicala dinti frontali</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Rezectie apicala dinti laterali</p>
                                </div>
                                <div className="column">
                                    <p>450 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Regularizare creasta</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Premolarizare</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Decapusonare</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Chistectomie</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aditie os</p>
                                </div>
                                <div className="column">
                                    <p>100 - 300 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Lifting sinusal</p>
                                </div>
                                <div className="column">
                                    <p>500 euro</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 7 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Analiza microbiologica complexa</p>
                                </div>
                                <div className="column">
                                    <p>1200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Analiza microbiologica pe arcada standard</p>
                                </div>
                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Chiuretaj subgingival pe hemiarcada inchis</p>
                                </div>
                                <div className="column">
                                    <p>250 lei / arcada</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Imobilizare</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Aplicare pasta antibiotic</p>
                                </div>
                                <div className="column">
                                    <p>50 lei / sedinta</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 8 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Implant endo-osos</p>
                                </div>
                                <div className="column">
                                    <p>500 euro / element</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Bon protetic metalic</p>
                                </div>
                                <div className="column">
                                    <p>60 euro / element</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Bon protetic zirconiu</p>
                                </div>
                                <div className="column">
                                    <p>160 euro / element</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Coroana metalo - ceramica pe implant</p>
                                </div>
                                <div className="column">
                                    <p>250 euro / element</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 9 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Ablatie lucrari dentare</p>
                                </div>
                                <div className="column">
                                    <p>50 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Coroana provizorie</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>RCR Metal</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>RCR Fibra de sticla</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza KEMENY</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Incrustatii ceramica</p>
                                </div>
                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Coroana Definitiva acrilat</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Coroana turnata CR-CO</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Coroana metalo - ceramica semifizionomica</p>
                                </div>
                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Coroana total fizionomica metalo - ceramica</p>
                                </div>
                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>
                            
                            {/* HERE */}

                            <div className="row">
                                <div className="column">
                                    <p>Coroana ceramica - suport zirconiu</p>
                                </div>
                                <div className="column">
                                    <p>850 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza protetica acrilica</p>
                                </div>
                                <div className="column">
                                    <p>1200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza acrilica cu sisteme speciale (capse)</p>
                                </div>
                                <div className="column">
                                    <p>2000 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza scheletata</p>
                                </div>
                                <div className="column">
                                    <p>2500 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Rebazare</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Reparatie proteza</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Inlocuire sisteme speciale</p>
                                </div>
                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 10 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Fateta compozit (in cabinet)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Fateta ceramica</p>
                                </div>
                                <div className="column">
                                    <p>300 euro - PRET PROMO</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Albire dinti devitali</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Albire la domiciliu (include gutierele)</p>
                                </div>
                                <div className="column">
                                    <p>1200 lei Enlighten <br />
                                        800 lei Opalescence
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aplicare bijuterii dentare</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 11 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Fluorizare (ambele arcade)</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinti temporari</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie coroana cu glassionomer</p>
                                </div>
                                <div className="column">
                                    <p>130 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie coroana cu compozit</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        <div className="names-container">

                            {window.innerWidth <= 900 && activeIdx < 0 ? <div className={`name-container ${activeIdx === 0 ? "active" : ""}`} onClick={() => changeActiveIdx(0)}>
                                <p>Servicii terapie, profilaxie, igienizare	</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 1 ? <div className={`name-container ${activeIdx === 1 ? "active" : ""}`} onClick={() => changeActiveIdx(1)}>
                                <p>Servicii odontoterapie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 2 ? <div className={`name-container ${activeIdx === 2 ? "active" : ""}`} onClick={() => changeActiveIdx(2)}>
                                <p>Ortodontie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 3 ? <div className={`name-container ${activeIdx === 3 ? "active" : ""}`} onClick={() => changeActiveIdx(3)}>
                                <p>Servicii endodontie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 4 ? <div className={`name-container ${activeIdx === 4 ? "active" : ""}`} onClick={() => changeActiveIdx(4)}>
                                <p>Servicii chirurgie mica, urgente</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 5 ? <div className={`name-container ${activeIdx === 5 ? "active" : ""}`} onClick={() => changeActiveIdx(5)}>
                                <p>Pedodontie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 6 ? <div className={`name-container ${activeIdx === 6 ? "active" : ""}`} onClick={() => changeActiveIdx(6)}>
                                <p>Servicii chirurgie omf (medic specialist)</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}
                            
                            {window.innerWidth <= 900 && activeIdx < 7 ? <div className={`name-container ${activeIdx === 7 ? "active" : ""}`} onClick={() => changeActiveIdx(7)}>
                                <p>Servicii parodontologie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 8 ? <div className={`name-container ${activeIdx === 8 ? "active" : ""}`} onClick={() => changeActiveIdx(8)}>
                                <p>Servicii implantologie</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 9 ? <div className={`name-container ${activeIdx === 9 ? "active" : ""}`} onClick={() => changeActiveIdx(9)}>
                                <p>Servicii protetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 10 ? <div className={`name-container ${activeIdx === 10 ? "active" : ""}`} onClick={() => changeActiveIdx(10)}>
                                <p>Servicii estetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 11 ? <div className={`name-container ${activeIdx === 11 ? "active" : ""}`} onClick={() => changeActiveIdx(11)}>
                                <p>Servicii pedodontie (Tratamente copii)</p>
                                <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                            </div> : null}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Treatments;