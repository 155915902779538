import { useState, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import HeaderHome from '../../Components/Header_Home/Header_Home';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faAngleLeft, faAngleRight, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import Raluca from "../../imgs/raluca.svg";
import Bianca from "../../imgs/bianca.png";
import nr1 from "../../imgs/poza_1.jpeg";
import nr2 from "../../imgs/poza_2.jpeg";
import nr3 from "../../imgs/poza_3.jpeg";
import nr4 from "../../imgs/poza_4.jpeg";
import nr5 from "../../imgs/poza_5.jpeg";
import nr6 from "../../imgs/poza_6.jpeg";
import dentoZenLogo from "../../imgs/logo2.svg";
import { faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons';

const Home = ({ data }: { data: any }) => {

    const [cookies, setCookies] = useCookies();
    const [doctorsIndex, setDoctorsIndex] = useState(0);

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");


    const doctors = useMemo(() => {
        return [
            {
                name: "Cristescu Bianca",
                type: "Medic stomatolog generalist",
                image: Bianca,
            },
            {
                name: "Dr. Tăutu Raluca",
                type: "Specialist parodontologie",
                image: Raluca,
                desc: "Dr. Raluca Tăutu, medicul nostru specializat în tratamente parodontologice, pasionată și de protetică dentară, ce va avea grijă ca nu doar dinții tăi să se păstreze zen, dar și starea ta de spirit. "
            },
           
        ];
    }, []);


    let slideIndex = 1;

    useEffect(() => {
        showSlides(slideIndex);
    }, [document.getElementsByClassName("mySlides"), document.getElementsByClassName("dot")]);


    function plusSlides(n: number) {
        showSlides(slideIndex += n);
    }

    function currentSlide(n: number) {
        showSlides(slideIndex = n);
    }

    function showSlides(n: number) {
        let i;
        let slides: any = document.getElementsByClassName("mySlides");
        if (n > slides.length) { slideIndex = 1 }
        if (n < 1) { slideIndex = slides.length }
        for (i = 0; i < slides.length; i++) {
            if (slides[i]?.style) slides[i].style.display = "none";
        }

        slides[slideIndex - 1].style.display = "block";
    }



    return (
        <div className="home-container">
            <HeaderHome data={data} activePage="home" />

            <div className="content">

                <div className="block-1">
                    <h2 className="title">
                        <span>Zâmbetul</span> tău <br />
                        de la A la <span className='swoosh'>ZEN.</span>

                    </h2>

                    <p className="description">{cookies.language === "en" ? "Come meet our team." : "Echipa DentoZen este un grup dedicat de profesioniști în domeniul stomatologiei care se străduiesc să ofere cele mai bune servicii și îngrijiri pacienților lor."}</p>

                    {/* <button type="button" onClick={() => {

                    }}>
                        <p>{cookies?.language === "en" ? "Contact us" : "Contactează-ne"}</p>
                    </button> */}

                    {/* <p className="sm-text">{cookies?.language === "en" ? "No credit card required" : "Fără card de credit"}</p> */}
                </div>


                <h2 className="sm-title title" style={{ marginBottom: "5rem" }}>

                    Hai să cunoști echipa noastră.
                </h2>

                <div className="block-4">


                    <div className="block-container">
                        <h2>{doctors[doctorsIndex].name}</h2>
                        <p><b>{doctors[doctorsIndex].type}</b></p>


                        <p>
                            {doctors[doctorsIndex].desc}
                        </p>
                    </div>

                    <div className="shadow-line"></div>


                    {/* Avantajele noastre ca la bookr */}


                    <div className="block-container">
                        <div>
                            <FontAwesomeIcon style={{ opacity: doctorsIndex === 0 ? 0 : 1 }} icon={faAngleLeft} className="arrow-icon" onClick={() => {
                                if (doctorsIndex === 0) return;
                                setDoctorsIndex(curr => curr - 1);
                            }} />
                            <img src={doctors[doctorsIndex].image} className="mockup" alt="mockup" />
                            <FontAwesomeIcon style={{ opacity: doctorsIndex === doctors.length - 1 ? 0 : 1 }} icon={faAngleRight} className="arrow-icon" onClick={() => {
                                if (doctorsIndex === doctors.length - 1) return;
                                setDoctorsIndex(curr => curr + 1);
                            }} />
                        </div>

                    </div>
                </div>

                <div className="block-5 block-container">
                    <h2 className="title sm-title">
                        <span>Despre noi</span><br />
                    </h2>

                    <p className="description">Cabinetul stomatologic este dotat cu echipamente de ultimă generație, servicii complete și o echipă dedicată de medici, pasionați și cu experiență de lucru, care prin dobândirea experienței la numeroase congrese și cursuri, atât naționale cât și internaționale, te vor întâmpina mereu cu cele mai bune planuri de tratament.</p>
                  
                </div>

                <div className="block-5 block-container">
                    <h2 className="title sm-title">
                        <span>Galerie</span><br />
                    </h2>

                    <div className="slideshow-container">

                        <div className="mySlides fade">
                            <div className="numbertext">1 / 5</div>
                            <img src={nr2} alt="Poza cabinet"  style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">2 / 5</div>
                            <img src={nr1} alt="Poza cabinet 2" style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">3 / 5</div>
                            <img src={nr3} alt="Poza cabinet 3" style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">4 / 5</div>
                            <img src={nr4} alt="Poza cabinet 4" style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">5 / 5</div>
                            <img src={nr5} alt="Poza cabinet 5" style={{ width: "100%" }} />
                        </div>

                        <div className="prev" onClick={() => plusSlides(-1)}>
                            <FontAwesomeIcon icon={faAngleLeft} className="angle-icon" />
                        </div>

                        <div className="next" onClick={() => plusSlides(1)}>
                            <FontAwesomeIcon icon={faAngleRight} className="angle-icon" />
                        </div>

                    </div>
                </div>


                <div className="block-6 block-container">
                    <h2 className="title sm-title">Contactează-ne</h2>
                    <br />


                </div>

                <div className="contact">
                    <div className="contact-container">
                        <div className="contact-info">
                            <a href="https://maps.app.goo.gl/eWeGwFnzSWXN5tUU7" target="_blank">
                                <div className="box">
                                    <FontAwesomeIcon icon={faAddressCard} className="icon" />
                                    <div className="text-container">
                                        <h3>Adresa</h3>
                                        <p>Strada Foisorului 4 bloc F1C scara 2, apartament 40 sector 3, Strada Foișorului 4, București 031172</p>
                                    </div>
                                </div>
                            </a>
                            
                            
                            <a href="tel:0734893938">
                                <div className="box">
                                    <FontAwesomeIcon icon={faPhone} className="icon" />
                                    <div className="text-container">
                                        <h3>Telefon</h3>
                                        <p>0734 893 938</p>
                                    </div>
                                </div>
                            </a>
                         

                            <a href="mailto:smile@dentozen.ro">
                                <div className="box">
                                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                    <div className="text-container">
                                        <h3>Email</h3>
                                        <p>smile@dentozen.ro</p>
                                    </div>
                                </div>
                            </a>

                            <div className="box">
                                    <FontAwesomeIcon icon={faClock} className="icon" />
                                    <div className="text-container">
                                        <h3>Program</h3>
                                        <p>Luni-Vineri 09:00 - 18:00</p>
                                    </div>
                            </div>
                          
                        </div>
                    </div>

                    <div className="contact-form">
                        <form action="">
                            <h2>Trimite mesaj</h2>
                            <div className="input-box">
                                <input aria-label='firstname' type="text" name="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)}  id="firstname" />
                                <span>Nume</span>
                            </div>

                            <div className="input-box">
                                <input type="text" aria-label='lastname' name="lastname" id="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                <span>Prenume</span>
                             
                            </div>

                            <div className="input-box">
                                <input type="text" name="phone" aria-label='phone' id="phone" value={phone} onChange={(e) => setPhone(e.target.value)}  />
                                <span>Telefon</span>
                            </div>

                            <div className="input-box">
                                <input type="text" name="email" id="email" aria-label='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                <span>Email (opțional)</span>
                            </div>

                            <div className="input-box" onClick={() => {
                                setEmail("");
                                setFirstName("");
                                setLastName("");
                            }}>
                                <input type="submit" value="Trimite" />
                            </div>
                        </form>
                    </div>
                </div>
{/* 
                <footer>

                    <div className="section">
                        <h2 className="section-title">Informatii de contact</h2>

                        <div className="section-container">
                            <i className="icon fas fa-phone"></i>
                            <p><b>Telefon:</b> +40 734 893 938</p>
                        </div>

                        <a href="tel:+40734893938" aria-label="Apeleaza">
                            <button>Apeleaza</button>
                        </a>


                        <div className="section-container">
                            <i className="icon far fa-map"></i>
                            <p><b>Adresa:</b> Strada Foisorului 4 bloc F1C scara 2, apartament 40 sector 3, Strada Foișorului 4, București 031172</p>
                        </div>

                        <a href="https://maps.app.goo.gl/aPWoqFqY6S58XDy76" aria-label="Vezi adresa" target="_blank">
                            <button>Vezi adresa</button>
                        </a>

                    </div>

                    <div className="section">
                        <h2 className="section-title">Program de lucru</h2>
                        <p><b>Luni:</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 09:00 - 16:00</p>
                        <p><b>Marti:</b> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;09:00 - 16:00</p>
                        <p><b>Miercuri:</b> &nbsp; &nbsp;09:00 - 16:00</p>
                        <p><b>Joi:</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;09:00 - 16:00</p>
                        <p><b>Vineri:</b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;09:00 - 16:00</p>
                        <p><b>Sambata:</b>&nbsp; &nbsp; &nbsp;Închis</p>
                        <p><b>Duminica:</b>&nbsp; &nbsp; Închis</p>
                    </div>


                </footer> */}

                <div className="block-6 block-container">
                    <div className="google-maps-container">
                            <iframe title="Locatie" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.540104448818!2d26.122679800000004!3d44.4220821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1fee0355e51f3%3A0xd8a792edc49e59c3!2sIndividual%20Medical%2C%20Ana%20Maria%20Ur%C5%9Feanu!5e0!3m2!1sen!2sro!4v1713125314265!5m2!1sen!2sro" width="600" height="450" style={{border: 0}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>                    
                    </div>
                </div>


                <div className="fixed-icon-container">
                    <a href="tel:+40734893938" aria-label="Buton Telefon">
                        <FontAwesomeIcon icon={faPhoneAlt} className="fixed-icon" />
                    </a>
                </div>


                <div className="block-7 final-block">
                    <div className="logo">

                    </div>
                    <h2 className="title last">Vino la <span>DentoZen.</span> <br /> Zambetul tau de la A la <span>ZEN.</span> </h2>

                    <img src={dentoZenLogo} alt="logo DentoZen" />

                    <p>© {new Date().getFullYear()}. All rights reserved.</p>
                    <p className="created-by">{cookies?.language === "en" ? "Created by Barbu Mihai." : "Creat de Barbu Mihai."}</p>
                </div>

            </div>

            {/* <Footer /> */}

        </div>
    );
};

export default Home;