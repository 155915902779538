import { useCookies } from "react-cookie";
import "./Header_Home.css";
import ZenLogoWhite from "../../imgs/logo.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";

const HeaderHome = ({ data, activePage } : { data: any, activePage: string | null }) => {

    const [cookies, setCookie] = useCookies();
    const navigation = useNavigate();

    const headerRef = useRef<HTMLDivElement | null>(null);

    return (
        <header ref={headerRef} className="home-header">
            <div className="list">
                <div className="left-container">
                
                    <div className="items">
                        <p className={`item ${activePage === "home" ? "active" : ""}`}>
                            <a href="/">{cookies?.language === "en" ? "Home" : "Acasa" }</a>
                        </p>
                        <p className={`item ${activePage === "treatments" ? "active" : ""}`}>
                            <a href="/tratamente">{cookies?.language === "en" ? "Treatments" : "Tratamente"}</a>
                        </p>
                        {/* <p className={`item ${activePage === "contact" ? "active" : ""}`}>
                            <a href="/contact">Contact</a>
                        </p> */}
                    </div>
                </div>

                <div className="logo">
                    
                    <img src={ZenLogoWhite} alt="Zentec Logo" />
                    <p className="logo-text">ZenVolt</p>
                    
                </div>
            
                {/* <p className="item">Zen news</p> */}

                <div className="right-container">
                    {/* <div className="button" onClick={() => foundUser ? navigation("/categories") : navigation("/login")}>
                       
                    </div> */}
                </div>
            </div>
            
            <div className="navigation-icon" onClick={() => headerRef?.current?.classList?.toggle("active-navigation")}>
                <div className="line" />
                <div className="line" />
                <div className="line" />
            </div>
        </header>
    );
};

export default HeaderHome;